import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Aboutimg from "../assets/aboutimg.png";
import { AllImage } from "../styling/style";
import styled from "@emotion/styled";
import background from "../assets/aboutback.mp4";

const AboutTypo = styled(Typography)`
  color: #ffffff;
  text-align: center;
  max-width: 800px;
  margin: auto;
  font-family: knights2;
  font-size: 18px;
  @media(max-width: 599px){
    font-size: 14px;
  }
`;
const Video = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  right: 0%;
  object-fit: cover;
  z-index: -1;
`;
const GridContainer = styled(Grid)`
  position: absolute;
  top: 10%;
  right: 0%;
  z-index:999;
  padding: 20px 0;
  @media (max-width: 850px) {
    /* position: static; */
  }
`;
const BackgroundDiv = styled.div`
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  min-height: 100vh;
  mix-blend-mode: plus-lighter;
`;
const BackgroundShadowDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  box-shadow: 0px -35px 28px 0px rgba(0, 0, 0, 1) inset,
    0px 35px 28px 13px rgba(0, 0, 0, 1),
    0px 35px 28px 0px rgba(0, 0, 0, 1) inset,
    0px -35px 28px 9px rgba(0, 0, 0, 1);
  z-index: 0;
`;
const About = () => {
  return (
    <BackgroundDiv>
      <BackgroundShadowDiv></BackgroundShadowDiv>
      <Video muted loop autoPlay>
        <source src={background} />
      </Video>
      <Container maxWidth="lg" id="about">
        <GridContainer container>
          <Grid xs={12} sx={{ padding: "5% 0%", textAlign: "center" }}>
            <AllImage mw="15%" mw599="50%" src={Aboutimg} />
          </Grid>
          <Grid xs={12}>
            <AboutTypo>
              Welcome to the Templar Token, where history meets cutting-edge
              technology in a thrilling fusion of the past and future. Our
              journey begins with the enigmatic Knights Templar, inspiring us to
              usher their legacy into the digital age.
            </AboutTypo>
          </Grid>
          <Grid xs={12} sx={{ padding: "3% 0%" }}>
            <AboutTypo>
              We are committed to authenticity and security, ensuring that our
              community can safely explore the rich tapestry of the Knights
              Templar universe while forging a path towards the future of
              cryptocurrency. Join us in rewriting history with the Templar
              Token, where we unite crypto and the Knights Templar, opening
              doors to a future filled with endless possibilities.{" "}
            </AboutTypo>
          </Grid>
        </GridContainer>
      </Container>
    </BackgroundDiv>
  );
};

export default About;
