
import './App.css';
import Main from './sections/main';

function App() {
  return (
    <div >
      <Main/>
    </div>
  );
}

export default App;
