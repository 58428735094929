import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import Templar from "../assets/templarimg.png";
import BackgroundTemplar from "../assets/templarback.png";
import TemplarRed from "../assets/templarpic.png";
import styled from "@emotion/styled";
import Dex from "../assets/dextool.png";
import Etherscan from "../assets/etherscan.png";
import uniSwap from "../assets/uniswap.png";

const Background = styled.div`
  background-image: url(${BackgroundTemplar});
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
  padding:40px 0;
  min-height: 100vh;
  display: grid;
  place-items: center;
`;

const FlexGrid = styled(Grid)`
  display: flex;
  flex-direction: ${(props) => (props.fd ? props.fd : "row")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  @media (max-width: 700px) {
    flex-direction: ${(props) => (props.fd599 ? props.fd599 : "")};
    padding-top: 20px;
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "30px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  position: relative;
  z-index: 9;
  transition: 0.4s ease-in-out;

  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;


const Heading = styled(Typography)`
  color: #fff;
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  font-size: ${(props) => (props.fs ? props.fs : "56px")};
  font-family: ${(props) => (props.fm ? props.fm : "knights2")};
  text-shadow: ${(props) => (props.ts ? "0px 2px 10px #ffffff" : "none")};
  text-transform: capitalize;
  z-index: 999;
  position: relative;
  @media (max-width: 899px) {
    padding: ${(props) => (props.p899 ? props.p899 : "0px")};
  }
  @media (max-width: 599px) {
    font-size: ${(props) => (props.fs599 ? props.fs599 : "42px")};
    padding: ${(props) => (props.p599 ? props.p599 : "0px")};
  }
  @media (min-width: 1399px) {
    font-size: ${(props) => props.fs1399};
  }
`;
const BackgroundShadowDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0px -35px 28px 0px rgba(0, 0, 0, 1) inset,
    0px 35px 28px 13px rgba(0, 0, 0, 1),
    0px 35px 28px 0px rgba(0, 0, 0, 1) inset,
    0px -35px 28px 9px rgba(0, 0, 0, 1);
  z-index: 0;
`;
const TemplarSection = () => {
  return (
    <div style={{ backgroundColor: "#000000" }}>
        <BackgroundShadowDiv/>
      <Background>
        <Container maxWidth="lg" id="templar">
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <FlexGrid item xs={12}>
              <Image mw="40%" mw599="70%" src={Templar} />
            </FlexGrid>
            <Grid item xs={12}>
              <Heading p="20px 0 0" p599="20px 0 0" fs="40px" fs599="34px">
              $KNIGHTS
              </Heading>
            </Grid>
            <FlexGrid item jc="end" xs={3} sm={4} >
              <Heading p="10px 0 0" ta="right" fs="40px" fs599="26px">
              10mill
              </Heading>
            </FlexGrid>
            <FlexGrid item xs={6} sm={4}>
              <Image mw="80%" mw599="120%" src={TemplarRed} />
            </FlexGrid>
            <FlexGrid item jc="start" xs={3} sm={4}>
              <Heading p="10px 0 0" ta="left" fs="40px" fs599="26px">
              1/2
              </Heading>
            </FlexGrid>
            <Grid item xs={12}>
              <Heading p="0px 0 0" p599="0px 0 0" fs="22px" fs599="16px">
                0000xxx000000xxxxxx00X0
              </Heading>
            </Grid>
            <FlexGrid
              item
              jc="center"
              fd599="column"
              p="30px 0"
              xs={12}
              sm={10}
              md={8}
              sx={{ columnGap: "40px", rowGap: "20px" }}
            >
              <Image mw="100px" src={Etherscan} />
              <Image mw="100px" src={Dex} />
              <Image mw="100px" src={uniSwap} />
            </FlexGrid>
          </Grid>
        </Container>
      </Background>
    </div>
  );
};
export default TemplarSection;
