import { Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import Logo from "../assets/logo.png";
import XIcon from "../assets/xicon.png";
import Telegram from "../assets/telegram.png";
import { AllImage, ListItems, ListStyle, UList } from "../styling/style";
import styled from "@emotion/styled";
import BackgroundVedio from "../assets/headerback.mp4";
import About from "./2-about";
import TemplarSection from "./3-templar";
import End from "./4-disclaimer";
import { DrawerMain } from "../components/drawer";
import audio from "../assets/audio.mp3";
import BackgroundVedio2 from "../assets/orange-sparks.mp4";
import MobileVedio from "../assets/mobileview.mp4";
import PopupImg from "../assets/popupimg.png";
import Enter from "../assets/enter.png";

const MenuGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1% 0%;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  @media (max-width: 600px) {
    display: none;
  }
`;
const Video2 = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  z-index: 0;
  opacity: 1;
  mix-blend-mode: plus-lighter;
`;
const MobileVedio1 = styled.video`
  display: none;
  @media (max-width: 600px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    display: block;
  }
`;
const Background = styled.div`
  background-image: url(${BackgroundVedio});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 40px 40px #000000, inset 0px 0px 40px 40px #dba632;
`;
const ContainerWraper = styled(Container)`
  width: 100%;
  height: calc(100vh);
  padding: 0 !important;
  background: linear-gradient(#000000, #ff730095, #000000);
  position: fixed;
  z-index: 999999999999999999999999;
  transition: 0.6s ease-in;
`;
const GridWraper = styled(Grid)`
  width: 100%;
  height: 60%;
  background-image: url(${PopupImg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  flex-direction: ${(props) => (props.fd ? props.fd : "column")};
  align-items: ${(props) => (props.ai ? props.ai : "center")};
  justify-content: ${(props) => (props.jc ? props.jc : "center")};
  padding: ${(props) => (props.p ? props.p : "0px")};
  margin: ${(props) => (props.m ? props.m : "0")};
  box-shadow: 0px 0px 100px #ff7300;
  @media (max-width: 700px) {
    flex-direction: ${(props) => (props.fd599 ? props.fd599 : "")};
    padding-top: 0px;
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "20%")};
  margin: ${(props) => (props.m ? props.m : "0")};
  position: relative;
  z-index: 9;
  transition: 0.4s ease-in-out;

  /* align-items: ${(props) => (props.ai ? props.ai : "center")}; */
  @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`;
const BackgroundShadowDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  box-shadow: 0px -35px 28px 0px rgba(0, 0, 0, 1) inset;
  z-index: 0;
`;
const Main = () => {
  const [isWelcome, setIsWelcome] = useState(false);
  const [show, setShow] = useState("show");
  const [imageClass, setImageClass] = useState("show");

  const playAudio = () => {
    setIsWelcome(true);
    setShow("hide");
    setImageClass("hideImage");
  };

  return (
    <div
      style={{
        backgroundColor: "#000000",
        minHeight: "100vh",
        overflow: "auto",
      }}
    >
      <BackgroundShadowDiv />
      {isWelcome ? (
        <audio autoPlay loop>
          <source src={audio} />
        </audio>
      ) : (
        ""
      )}
      <ContainerWraper maxWidth="xxl" className={show}>
        <Container
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Grid
            container
            sx={{
              display: "grid",
              placeItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <GridWraper item jc="end" ai="center" xs={12} sm={10} md={8}>
              <Image
                m="0 0 20px"
                m599="0 0 20px"
                className={imageClass}
                onClick={() => playAudio()}
                style={{ cursor: "pointer" }}
                mw="20%"
                mw599="30%"
                src={Enter}
              />
            </GridWraper>
          </Grid>
        </Container>
      </ContainerWraper>
      <Video autoPlay loop muted>
        <source src={BackgroundVedio} />
      </Video>
      <MobileVedio1 autoPlay loop muted>
        <source src={MobileVedio} />
      </MobileVedio1>
      <Background>
        <Video2 autoPlay loop muted>
          <source src={BackgroundVedio2} />
        </Video2>

        <Container maxWidth="lg" sx={{ zIndex: "1" }}>
          <Grid
            container
            sx={{
              height: "100vh",
              display: "flex",
              alignContent: "space-between",
              justifyContent: "center",
              paddingBottom: "50px",
            }}
          >
            <MenuGrid xs={12}>
              <AllImage mw="30px" src={Logo} />
              <UList>
                <ListStyle>
                  <ListItems href="">Home</ListItems>
                </ListStyle>
                <ListStyle>
                  <ListItems href="#about">About</ListItems>
                </ListStyle>
                <ListStyle>
                  <ListItems href="#templar">Tokenomics</ListItems>
                </ListStyle>
                <ListStyle>
                  <a href="https://twitter.com/Templar_Erc20" target="blank">
                    <AllImage mw="30px" src={XIcon} />
                  </a>
                </ListStyle>
                <ListStyle>
                  <a href="https://t.me/Templar_Erc20" target="blank">
                    <AllImage mw="30px" src={Telegram} />
                  </a>
                </ListStyle>
              </UList>
              <DrawerMain />
            </MenuGrid>
            <Typography
              variant="h5"
              sx={{
                color: "#FFFFFF",
                fontFamily: "knights2",
                textAlign: "center",
                position: "relative",
                zIndex: "99999",
              }}
            >
              Contract Address: 000Xxx000xxx000
            </Typography>
          </Grid>
        </Container>
      </Background>
      <About />
      <TemplarSection />
      <End />
    </div>
  );
};
export default Main;
