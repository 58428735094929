import {  Container, Grid, Typography } from "@mui/material";
import React from "react";
import Disclaimer from "../assets/disclaimerimg.png";
import { AllImage,  } from "../styling/style";
import styled from "@emotion/styled";
import XIcon from "../assets/xicon.png";
import Telegram from "../assets/telegram.png";

const AboutTypo = styled(Typography)`
  color: #ffffff;
  text-align: center;
  max-width: 770px;
  margin: auto;
  font-size: 18px;
  font-family: knights2;
  @media(max-width: 599px){
    font-size: 14px;
  }
`;
const SocialGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  column-gap: 20px;
  padding-top: 20px;
`;
const SocialTag = styled(Typography)`
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2% 1%;
  font-family: knights2;
`;
const HeadingGrid = styled(Grid)`
  padding: 7% 0% 4%;
  text-align: center;
  @media (max-width: 600px) {
    padding: 20% 0% 15%;
  }
`;
const End = () => {
  return (
    <Container maxWidth="lg">
      <Grid container>
        <HeadingGrid xs={12}>
          <AllImage mw="25%" mw599="50%" src={Disclaimer} />
        </HeadingGrid>
        <Grid xs={12}>
          <AboutTypo>
            The Templar Token website is for educational and entertainment
            purposes, and while we aim for historical accuracy, content may be
            creatively interpreted. The Templar Token is a cryptocurrency,
            subject to market fluctuations. External links are provided for
            reference, and users should exercise caution. Participation is at
            your own risk; consider professional advice for financial decisions.
          </AboutTypo>
          <SocialGrid xs={12}>
            <a href="https://twitter.com/Templar_Erc20" target="blank" style={{ cursor:"pointer" ,textDecoration:"none"}}>
              <SocialTag>
                <AllImage
                  mw="30px"
                  src={XIcon}
                  style={{ padding: "0% 10px" }}
                />
                X
              </SocialTag>
            </a>
            <a href="https://t.me/Templar_Erc20"  target="blank" style={{ cursor:"pointer" ,textDecoration:"none"}}>
              <SocialTag>
                <AllImage
                  mw="30px"
                  src={Telegram}
                  style={{ padding: "0% 10px" }}
                />
                Telegram
              </SocialTag>
            </a>
          </SocialGrid>
          <Grid xs={12} sx={{ padding: "2% 0%" }}>
            <AboutTypo>© 2023 Knights of Templar Eth</AboutTypo>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
export default End;
