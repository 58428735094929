import styled from "@emotion/styled";

const AllImage = styled.img`
    width: 100%;
    height: auto;
    max-width: ${props=>props.mw? props.mw : ''};
    @media (max-width: 599px) {
    max-width: ${(props) => (props.mw599 ? props.mw599 : "")};
    margin: ${(props) => (props.m599 ? props.m599 : "0")};
    display: ${(props) => (props.disp ? props.disp : "")};
    visibility: ${(props) => (props.vis ? props.vis : "")};
  }
`
const UList = styled.ul `
    display: flex;
    align-items: center;
    position: relative;
    z-index: 3;
    @media (max-width: 750px){
        display: none;
    }
`
const ListStyle = styled.li`
    list-style: none;
    padding: 0px 15px;
    position:relative;
    z-index:9; 
`
const ListItems = styled.a`
    text-decoration: none;
    color: #FFC654;
    font-family: knights2;
    font-size: 25px;
    
`
export {UList, ListStyle, ListItems, AllImage}